import React from "react";
import Helmet from 'react-helmet'

import { graphql } from "gatsby";
import LayoutWeek from "../../components/Chronology/LayoutWeek";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../components/Chronology/Modules/ModMegaBanner";
import ModAlertBanner from "../../components/Chronology/Modules/ModAlertBanner";
import ModDottedLine from "../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../components/Textstyles/Styles";
import Showcase from "../../components/Chronology/Modules/Showcase";
import ModImage from "../../components/Chronology/Modules/ModImage";


const Week = (props) => {
  return (
    <LayoutWeek {...props}>
        <SiteData/>

      {/* Resumen semanal */}
      <SecWeekly period="01 Abril - 07 Abril" week=" Semana X">
        <SecWeeklyColumn>
          España alcanza el <strong>pico de la pandemia</strong>
          <ModDottedLine />
          Europa supera el <strong>1.000.000 de casos confirmados</strong>
          <ModDottedLine />
          Sanidad fija el <strong>importe máximo</strong> de venta de{" "}
          <strong>productos sanitarios</strong>
          <ModDottedLine />
          <strong>Datos de móviles</strong> para estudiar la eficacia del
          confinamiento sobre la <strong>dispersión de COVID-19</strong>
        </SecWeeklyColumn>
        <SecWeeklyColumn>
          <ModImage src="../images/svg/casos_confirmados_resumen_semana5.svg"/>
        </SecWeeklyColumn>
      </SecWeekly>

      {/* Contenido de la semana (listar aqui cada uno de los días de la semana) */}
      <Showcase />

      {/* Banners */}
      <ModMegaBanner>
        Este texto puede ir <strong>enfatizado en blanco</strong> o ir{" "}
        <StrongGold>enfatizado en amarillo</StrongGold>
      </ModMegaBanner>
      <ModAlertBanner>
        Este banner por defecto tiene el fondo amarillo si no incluye la props
        color
      </ModAlertBanner>
      <ModAlertBanner color="brand02">
        Pero si la incluye, toma el color de esa prop
      </ModAlertBanner>
      <br />
      <strong>↓ Module Mega Banner w/ Dark BG</strong>
      <ModMegaBanner bg="brand01">
        <strong>Primer día laborable </strong> de{" "}
        <StrongGold>confinamiento masivo </StrongGold> por la expansión de
        coronavirus por la expansión de coronavirus
      </ModMegaBanner>
    </LayoutWeek>
  );
};

export const pageQuery = graphql`
  query {
    allSitePage(
      filter: { id: { regex: "/semana-[0-9]/$/" } }
      sort: { fields: path, order: DESC }
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
`;
export default Week;


const SiteData = (_) => (
    <Helmet>
        <meta name="googlebot" content="noindex"/>
        <meta name="robots" content="noindex"/>
    </Helmet>
  );
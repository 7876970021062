

import React from "react";
import LayoutDay from "../LayoutDay";
import ContentLeft from "./ContentLeft";
import ContentRight from "./ContentRight";
import ModCCAATable from "./ModCCAATable";
import ModTwoCols from "./ModTwoCols";
import ModTwoColsAnim from "./ModTwoColsAnim";
import ModText from "./ModText";
import InlineLink from "./InlineLink";
import ModDotList from "./ModDotList";
import ModImage from "./ModImage";
import ModReferenceList from "./ModReferenceList";
import ModAnimation from "./ModAnimation";
import ReferenceRow from "./ReferenceRow";
import ModDatafactCountry from "./ModDatafactCountry";
import ModDatafactCCAA from "./ModDatafactCCAA";
import ModCovidHighlight from "./ModCovidHighlight";
import ModBuscandoDatos from "./ModBuscandoDatos";
import ModDashedLine from "./ModDashedLine";
import ModReport from "./ModReport";
import ModDailyHighlight from "./ModDailyHighlight";
import ModDataEu from "./ModDataEu";
import ModLethalityEu from "./ModLethalityEu";
import ModDottedLine from "./ModDottedLine";
import ModGraph from "./ModGraph";
import ModDataEs from "./ModDataEs";
import ModTwoImgCols from "./ModTwoImgCols";
import ModVideo from "./ModVideo";
import { StrongGold } from "../../Textstyles/Styles";

export const frontmatter = {
  title: "Día DD",
  week: "Semana 3",
  month: "Mar",
  day: "30",
  monthNumber: "03",
  date: "2020-03-30",
  path: "/cronologia/semana-3#dia-10",
};

export default (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <br />
        <strong>↓ Module DataES (w/ image)</strong>
        <p>Este módulo lo gestiona el LayoutDay automáticamente</p>
        <ModDataEs date="2020-03-30" />

        <br />
        <strong>↓ Module Report</strong>
        <ModReport
          text1="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          text2="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit"
        />

        <br />
        <strong>↓ Module Covid Highlight</strong>
        <ModCovidHighlight>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit sed eiusmod
          tempor incidunt
        </ModCovidHighlight>

        <br />
        <strong>↓ Module Daily Hightlight</strong>
        <ModDailyHighlight>
          <strong>Lorem ipsum dolor sit amet</strong>, consectetur adipiscing
          elit <StrongGold>sed eiusmod tempor</StrongGold> incidunt.
        </ModDailyHighlight>

        <br />
        <strong>↓ Module CCAA Table</strong>
        <ModCCAATable fecha={frontmatter.date} />

        <br />
        <strong>↓ Module Data Fact Country (w/ Magma + API)</strong>
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="IT"
          data="casosFallecidosDiario"
        />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="ES"
          data="casosFallecidos"
        />
        {/* //! Los datos de US no existen en la api (solo tiene paises europeos) */}
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="US"
          data="casosConfirmados"
        />
        <p>
          Si los datos que se quieren presentar no están disponibles en la API
          se pueden usar las props custom, "customText" y "customNumber". E.g.:
        </p>
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="ES"
          customText="Tasa de letalidad"
          customNumber="10,5%"
        />

        <br />
        <strong>↓ Module Data Fact CCAA (w/ Magma + API)</strong>
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-an"
          data="casosFallecidosDiario"
        />
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-md"
          data="casosHospitalizados"
        />
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-ri"
          data="casosUci"
        />
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-ex"
          data="casosFallecidos"
        />
        <p>
          Si los datos que se quieren presentar no están disponibles en la API
          se pueden usar las props custom, "customText" y "customNumber". E.g.:
        </p>
        <ModDatafactCCAA
          fecha={frontmatter.date}
          ccaa="es-ri"
          customText="Tasa de letalidad"
          customNumber="13,5%"
        />

        <br />
        <strong>↓ Module Data EU (w/ Magma + API)</strong>
        <ModDataEu fecha={frontmatter.date} />

        <br />
        <strong>↓ Module Lethality Eu (w/ Magma + API)</strong>
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />

        <br />
        <strong>↓ Module Graph</strong>
        <ModGraph name="16-04-Analisis-ES-EX" alt="" />
      </ContentLeft>

      <ContentRight>
        <br />
        <strong>↓ Module Dotted Line</strong>
        <ModDottedLine />

        <br />
        <strong>↓ Module Dashed Line</strong>
        <ModDashedLine />

        <br />
        <strong>↓ Module Image</strong>
        <ModImage src="/images/svg/03_abr_sector_transporte.svg" alt="Alternative dummy text" />

        <br />
        <strong>↓ Module Animation</strong>
        <ModAnimation svg="/images/svg/31_mar_transportista.svg"></ModAnimation>

        <br />
        <strong>↓ Module Text</strong>
        <ModText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod
          tempor incidunt ut labore et dolore magna aliqua.{" "}
          <InlineLink link="//">Ut enim ad minim veniam</InlineLink>, quis
          nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi
          consequat. Quis aute iure reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </ModText>

        <br />
        <strong>↓ Module Two Columns</strong>
        <ModTwoCols
          src="/images/svg/01_abr_agricultores.svg"
          alt="Alternative dummy text"
          small={false}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod
          tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex
          ea commodi consequat. Quis aute iure reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat
          cupiditat non proident, sunt in culpa qui officia deserunt mollit anim
          id est laborum.
        </ModTwoCols>

        <br />
        <strong>↓ Module Two Columns (w/ small=true)</strong>
        <ModTwoCols
          src="/images/svg/12_abr_bus.svg"
          alt="Alternative dummy text"
          small={true}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod
          tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex
          ea commodi consequat. Quis aute iure reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur.
        </ModTwoCols>

        <br />
        <strong>↓ Module Two Columns (w/ inverted=true)</strong>
        <ModTwoCols
          src="/images/svg/12_abr_bus.svg"
          alt="Alternative dummy text"
          inverted={true}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod
          tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex
          ea commodi consequat. Quis aute iure reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur.
        </ModTwoCols>

        <br />
        <strong>↓ Module Two Columns (w/ inverted=true and small=true)</strong>
        <ModTwoCols
          src="/images/svg/12_abr_bus.svg"
          alt="Alternative dummy text"
          small={true}
          inverted={true}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod
          tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex
          ea commodi consequat. Quis aute iure reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur.
        </ModTwoCols>

        <br />
        <strong>↓ Module Two Columns Animation </strong>
        <p>
          Es exactamente igual que Mod Two Columns y tiene todas sus props. Solo
          cambia la imagen por una animacion
        </p>
        <ModTwoColsAnim
          src="/images/anim/against-covid-badge.svg"
          alt="Alternative dummy text"
          small
        >
          Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed eiusmod tempor incidunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur.
        </ModTwoColsAnim>

        <br />
        <strong>↓ Module Two Columns w/ images</strong>
        <ModTwoImgCols
          src1="/images/svg/01_abr_agricultores.svg"
          alt1="Alternative dummy text"
          src2="/images/svg/01_abr_at_psicologica.svg"
          alt2="Alternative dummy text"
        />

        <br />
        <strong>↓ Module Reference List</strong>
        <ModReferenceList title="Guías y documentos">
          <ReferenceRow link="#" name="Nombre del link" />
          <ReferenceRow link="#" name="Nombre del link" />
          <ReferenceRow link="#" name="Nombre del link" />
        </ModReferenceList>

        <br />
        <strong>↓ Module WIP Message</strong>
        <ModBuscandoDatos />

        <br />
        <strong>↓ Module DotList</strong>
        <ModDotList
          item1="Una cosa"
          item2="Otra cosa más"
          item3="Puedo añadir tantas props como items de la lista que necesite"
          item4="Los nombres de las props pueden ser los que se quiera, es indiferente. Simplemente hay que asegurarse de que sean diferentes. En este caso se utilizan item1, item2, item3, item4... itemn+1"
        />

        <br />
        <strong>↓ Module Video</strong>
        <ModVideo src="//www.youtube.com/embed/b6Fgddtx6aw?rel=0" />
      </ContentRight>
    </LayoutDay>
  );
};
